export default [
  // {
  //   title: 'Agents',
  //   icon: 'UsersIcon',
  //   route: 'apps-users-list',
  // },
  {
    title: 'User Managment',
    icon: 'UsersIcon',
    route: 'apps-users-list',
    user: true
  },
  {
    title: 'Transactions',
    icon: 'GridIcon',
    route: 'apps-transactions',
    user: false
  },
  {
    title: 'Safelock',
    icon: 'LockIcon',
    route: 'apps-safelock',
    user: false
  },
  {
    title: 'Flex Savings',
    icon: 'LockIcon',
    route: 'apps-flex-savings',
    user: false
  },
  {
    title: 'Rates & Charges',
    icon: 'DollarSignIcon',
    route: 'apps-rate-charges',
    user: false
  },
  {
    title: 'Service Categories',
    icon: 'BoxIcon',
    route: 'apps-service-category',
    user: false
  },
  {
    title: 'Services',
    icon: 'PackageIcon',
    route: 'apps-services',
    user: false
  },
  {
    title: 'Feedback & Complains',
    icon: 'HeadphonesIcon',
    route: 'apps-supports',
    user: true
  },
  {
    title: 'ePin Requests',
    icon: 'RefreshCwIcon',
    route: 'apps-epin-requests',
    user: false
  },
  {
    title: 'Refer & Earn',
    icon: 'GitMergeIcon',
    route: 'apps-referrals',
    user: false
  },
  {
    title: 'WallX Vend Earning',
    icon: 'BriefcaseIcon',
    route: 'pages-earnings',
    user: false
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    route: 'apps-settings',
    user: false
  },
  {
    title: 'Email Broadcast',
    icon: 'InboxIcon',
    route: 'apps-email',
    user: true
  },
  {
    title: 'Admin',
    icon: 'UserIcon',
    user: false,
    children: [
      {
        title: 'Profile',
        route: 'pages-account-setting',
        user: false   
      },
      {
        title: 'Users',
        route: 'pages-users',
        user: false   
      },
    ],
    
  },
  
]
